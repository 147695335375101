import React from "react";
import { connect } from "react-redux";
import {
  submitSearchQuery,
  updateFocusedLocationOnly,
  clearSearch,
} from "../js-shared/lib/actions/searchActions";
import { fetchSearchedStationsNearBy } from "../js-shared/lib/actions/stationActions";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOnOutlined";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { useTranslation } from "react-i18next";
import InputAdornment from "@material-ui/core/InputAdornment";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import PropTypes from "prop-types";

const autocompleteService = { current: null };
var placeDetailsService = null;

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  textfield: {
    boxShadow: "0 0 3px rgba(0,0,0,0.3)",
    // boxShadow: "rgba(0, 0, 0, 0.13) 1px 2px 4px 1px",
    backgroundColor: "#fafafa",
    // backgroundColor: "#dedede",
    border: "none",
    borderRadius: "4px",
    // borderRadius: "34px",
    "&:hover": {
      // boxShadow: "0 0 1px rgba(0,0,0,0.2)",
      boxShadow: "0 0 5px rgba(0,0,0,0.2)",
      border: "none",
      borderRadius: "4px",
    },
  },
  notchedOutline: {
    borderRadius: "4px",
    borderWidth: "0px",
    borderColor: "f18d0f !important",
    // boxShadow: "0 0 5px rgba(241, 141, 15,0.2)",
  },
}));

function GoogleMapsSearch(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(props.initialValue);
  const [label, setLabel] = React.useState(props.label);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [noOptions, setNoOptions] = React.useState(false);
  // const [placeDetails, setPlaceDetails] = React.useState(null);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    loaded.current = true;
  }

  const showPosition = (event) => {
    props.updateFocusedLocationOnly(event.coords.latitude, event.coords.longitude);
    props.fetchSearchedStationsNearBy(event.coords.latitude, event.coords.longitude);
    props.onAfterGeolocationHandling();
  };
  
  const showError = (error) => {
    // console.log(error.code);
    // if (error.code === 1) {
    //   console.log("User denied permission");
    // } else if (error.code === 2) {
    //   console.log("Position is currently unavailable");
    // } else {
    //   console.log("Request Timed out");
    // }
  };
  
  const handleGeolocation = () => {
    navigator.geolocation.getCurrentPosition(showPosition, showError);
    props.onGeolocationHandling();
  };

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  const savePlaceDetails = (place_id) => {
    placeDetailsService.getDetails({ placeId: place_id }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        // console.log("val",props.validName)
        props.submitSearchQuery(place);
        if (props.label === t("Διεύθυνση (πχ Γράμμου 52, Μαρούσι)")) {
          if (props.name === "") {
            props.setValidName(true);
          }
        }
        // setPlaceDetails(place)
        props.updateFocusedLocationOnly(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );
        props.fetchSearchedStationsNearBy(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        )
        props.onSubmitSearchQuery();
      }
    });
  };

  React.useEffect(() => {
    let active = true;

    // Initialize AutoComplete Service
    if (
      !autocompleteService.current &&
      window.google &&
      window.google.maps.places
    ) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    // Initialize PlaceDetails Service
    // see why we need to create a map component here:
    // https://stackoverflow.com/questions/23460435/get-google-place-details-without-map
    if (!placeDetailsService && window.google) {
      var map = new window.google.maps.Map(document.createElement("div"));
      placeDetailsService = new window.google.maps.places.PlacesService(map);
    }
    if (!placeDetailsService) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    // Get autocomplete results
    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      freeSolo
      onOpen={() => {
        setNoOptions(true);
      }}
      onClose={() => {
        setNoOptions(false);
      }}
      // noOptionsText={}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      defaultValue={props.search.query}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (newValue) {
          savePlaceDetails(newValue.place_id);
        } else {
          props.clearSearch();
        }
      }}
      onInputChange={(event, newInputValue) => {
        setNoOptions(false);
        setInputValue(newInputValue);
        // props.submitSearchQuery(newInputValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          margin="normal"
          helperText={
            noOptions &&
            !props.disableUseMyLocation && (
              <Button
                color="primary"
                startIcon={<MyLocationIcon style={{ marginLeft: -120 }} />}
                fullWidth
                style={{ height: 50 }}
                onClick={() => handleGeolocation()}
              >
                <Typography style={{ marginLeft: -100 }}>
                  {t("Χρήση τρέχουσας τοποθεσίας")}
                </Typography>
              </Button>
            )
          }
          // helperText={props.helperText}
          fullWidth
          name="region"
          id="region"
          color="primary"
          // label={props.label}
          className={classes.textfield}
          placeholder={label}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <LocationOnIcon color="secondary" />
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
            classes: {
              notchedOutline: classes.notchedOutline,
            },
          }}
        />
      )}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );
        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
    // End of <Autocomplete>
  );
}

GoogleMapsSearch.propTypes = {
  onGeolocationHandling: PropTypes.func,
  onAfterGeolocationHandling: PropTypes.func,
  onSubmitSearchQuery: PropTypes.func,
  // TODO: Add the rest of props too
};

const noop = () => {};
GoogleMapsSearch.defaultProps = {
  onGeolocationHandling: noop,
  onAfterGeolocationHandling: noop,
  onSubmitSearchQuery: noop
}

const mapStateToProps = (state) => {
  return {
    search: state.search,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    submitSearchQuery: (search_query) =>
      dispatch(submitSearchQuery(search_query)),
    updateFocusedLocationOnly: (lat, lng) =>
      dispatch(updateFocusedLocationOnly(lat, lng)),
    fetchSearchedStationsNearBy: (lat, lng, zoom_level = 10) => dispatch(fetchSearchedStationsNearBy(lat, lng, zoom_level)),
    clearSearch: () => dispatch(clearSearch()),
  };
};

export default connect(
  mapStateToProps, // State
  mapDispatchToProps // ActionCreators
)(GoogleMapsSearch); // Main Component
